/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

* {
  font-family: "Bricolage Grotesque", sans-serif !important;
  font-optical-sizing: auto;
  font-weight: 500 !important;
  font-style: normal !important;
  font-variation-settings: "wdth" 87.5;
}
body {
  margin: 0;
}
.css-lu8frz.Mui-focused {
  font-size: 16px !important;
  padding-left: 0px !important;
}
.css-w0pj6f {
  display: none;
}

/* For WebKit browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 5px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #ffffff; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #dad9d8; /* Color of the scrollbar thumb */
  border-radius: 5px; /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #b3b3b3; /* Color of the scrollbar thumb on hover */
}

@media only screen and (max-width: 899px) {
  .css-mo2hh3 {
    display: block !important;
  }
  .css-15fqszo {
    display: block !important;
  }
  .css-975k0j {
    display: block !important;
  }
  .css-mz6m12-MuiPaper-root-MuiAppBar-root {
    display: block !important;
  }
  .css-wudu4t-MuiModal-root-MuiDrawer-root {
    display: block !important;
  }
  .css-13uoy6a {
    padding: 24px;
    padding-top: 110px !important;
    padding-bottom: 32px;
  }
}

@media only screen and (max-width: 1025px) {
  .css-iwncdf {
    width: 240px !important;
  }
  .css-iwncdf .MuiDrawer-paper {
    width: 240px !important;
  }
  .css-1th8wor-MuiDrawer-docked .MuiDrawer-paper {
    width: 200px !important;
  }
  /* .logo-coach {
    width: 190px !important;
  } */
  .css-1ixaedr {
    font-size: 10px !important;
  }
  .css-yk0h33 {
    font-size: 10px !important;
  }
  .buttonCal {
    font-size: 10px !important;
  }
}

@media only screen and (max-width: 375px) {
  .css-1ixaedr {
    font-size: 8px !important;
  }
  .css-yk0h33 {
    font-size: 8px !important;
  }
  .buttonCal {
    font-size: 8px !important;
  }
  .custom-toolbar span {
    font-size: smaller !important;
  }
  .css-1kh85lm {
    font-size: 10px !important;
  }
  .css-1m34xts {
    font-size: 8px !important;
  }
  .css-1u33czl {
    font-size: 16px !important;
  }
  .css-1ago85d {
    font-size: 0.8rem !important;
  }
}

.css-1ww2822.MuiTableRow-hover:hover {
  background-color: white !important;
}
.css-1ww2822.MuiTableRow-hover:hover {
  background-color: white !important;
}

@media only screen and (max-width: 1441px) {
  .buttonCal {
    font-size: 10px !important;
    padding: 5px 10px 5px 10px !important;
    height: 25px !important;
  }
  .css-1ixaedr .MuiSvgIcon-root {
    font-size: 12px !important;
  }
  .css-1ixaedr {
    font-size: 12px !important;
    padding: 3px 9px 3px 5px !important;
  }
  .css-yk0h33 {
    padding: 3px 9px 3px 5px !important;
  }
  .css-yk0h33 .MuiSvgIcon-root {
    font-size: 12px !important;
  }
  .custom-toolbar {
    padding: 15px 27px 10px 27px !important;
  }
}

.css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  box-shadow: unset !important;
  border-radius: 20px !important;
}

.css-pwxzbm {
  box-shadow: unset !important;
  border-radius: 20px !important;
}
